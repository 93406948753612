import React, { useState, useEffect } from 'react';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { Card, Col, Button, Form, Row, Modal, Spinner } from 'react-bootstrap';

export default function(props) {
    const [serverSMTP, setServerSMTP] = useState('');
    const [porta, setPorta] = useState('');
    const [secure, setSecure] = useState(false);
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [destinatario, setDestinatario] = useState('');

    const [passed, setPassed] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [titleMessage, setTitleMessage] = useState('Info');
    const [bodyMessage, setBodyMessage] = useState('ciao mondo');
    const [buttonCloseMessage, setButtonCloseMessage] = useState(true);

    useEffect(() => {
        getDataFromFirestore();
    }, []);
    const getDataFromFirestore = () => {
        setShowLoading(true);
        firebase.firestore().collection('settingsWebSite').doc('ModuloContatto').get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    if (data) {
                        console.log('@@@ DATA', data);
                        setForm(
                            data.serverSMTP,
                            data.porta,
                            data.secure,
                            data.account,
                            data.password,
                            data.destinatario
                        );
                        return ;
                    }
                }
                setForm(null, null, null, null, null, null);
            }).catch(error => {
                console.log('@@@ [ERROR]', error);
                setForm(null, null, null, null, null, null);
                showModalMessage('Errore', 'Errore: ' + error);
            });
    }
    const setForm = (_serverSMTP, _porta, _secure, _account, _pass, _dest) => {
        setServerSMTP(_serverSMTP);
        setPorta(_porta);
        setSecure(_secure);
        setAccount(_account);
        setPassword(_pass);
        setDestinatario(_dest);

        setShowLoading(false);
    }
    const setDataToFirestore = async () => {
        setShowLoading(true);
        try {
            await firebase.firestore().collection('settingsWebSite').doc('ModuloContatto').set({
                serverSMTP,
                porta,
                secure,
                account,
                password,
                destinatario,
            }, {merge: true});
            setShowLoading(false);
            showModalMessage('Info', 'Dati salvati con successo!');
        } catch (error) {
            console.log('@@@ [ERROR]', error);
            setShowLoading(false);
            showModalMessage('Errore', 'Errore: ' + error);
        }
    }

    const showModalMessage = (title, body, buttonClose = true) => {
        setTitleMessage(title);
        setBodyMessage(body);
        setButtonCloseMessage(buttonClose);
        setShowMessage(true);
    }

    useEffect(() => {
        setPassed(false);
    }, [serverSMTP, porta, secure, account, password, destinatario]);

    const checkConnection = async () => {
        showModalMessage('Test connessione', 'Connessione in corso...', false);
        
        const result = await _testConnection();

        if (result.result.status == 'OK') {
            showModalMessage('Connessione riuscita', 'Connessione effettuata con successo!<br><br>E\' stata inviata una email al destinatario, controllare la posta in arrivo.<br>Se hai ricevuto la email di prova salva le impostazioni prima di chiudere la finestra.', true);
            setPassed(true);
        } else {
            showModalMessage('Errore connessione', `Connessione non riuscita!<br><br>${JSON.stringify(result.result.error).split(',').join('<br>')}`, true);
            setPassed(false);
        }
    }
    const _testConnection = async () => {
        const response = await fetch('https://us-central1-aeffetermica-b2c11.cloudfunctions.net/TestConnectionServerSMTP', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({data: {
                serverSMTP, porta, secure, account, password, destinatario
            }}) // body data type must match "Content-Type" header
        });
        return await response.json();
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            marginTop: '80px',
        }}>
            <Col xs={12} sm={12} md={6} lg={5}>
                <Card body>
                    <Col>
                        <h1 style={{textAlign: 'center', marginBottom: '20px'}}>Impostazioni modulo di contatto</h1>

                        <Form>

                            <Row noGutters>
                                <Form.Group controlId="txtServerSMTP" style={{flex: 2, marginRight: '10px'}}>
                                    <Form.Label>Server SMTP</Form.Label>
                                    <Form.Control type="text" placeholder="Server SMTP"
                                        value={serverSMTP}
                                        onChange={e => setServerSMTP(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="txtPorta" style={{flex: 1}}>
                                    <Form.Label>Porta Server</Form.Label>
                                    <Form.Control type="text" placeholder="Porta Server"
                                        value={porta}
                                        onChange={e => setPorta(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <div className="mb-3">
                                <Form.Check 
                                    type={'checkbox'}
                                    id={`ckbUseSecureConn`}
                                    label="Usa connessione sicura"
                                    checked={secure}
                                    onClick={() => setSecure(!secure)}
                                    onChange={e => null}
                                />
                            </div>

                            <Row noGutters>
                                <Form.Group controlId="txtAccount" style={{flex: 1, marginRight: '10px'}}>
                                    <Form.Label>Account / Nome Utente</Form.Label>
                                    <Form.Control type="text" placeholder="Account / Nome Utente"
                                        value={account}
                                        onChange={e => setAccount(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="txtPassword" style={{flex: 1}}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Form.Group controlId="txtEmailAssistenza">
                                <Form.Label>Email destinatario</Form.Label>
                                <Form.Control type="text" placeholder="Email destinatario"
                                    value={destinatario}
                                    onChange={e => setDestinatario(e.target.value)}
                                />
                            </Form.Group>

                            <div className="mb-3">
                                <Button variant="info" type="button" block
                                    onClick={() => checkConnection()}
                                >
                                    EFFETTUA TEST CONNESSIONE
                                </Button>
                            </div>
                            
                            <Row noGutters>
                                <Button variant="primary" type="button" style={{flex: 1, marginRight: '10px'}}
                                    onClick={() => getDataFromFirestore()}
                                >
                                    ANNULLA
                                </Button>

                                <Button variant="success" type="button" style={{flex: 1}}
                                    disabled={!passed}
                                    onClick={() => {
                                        console.log('@@@ SUBMIT', serverSMTP, porta, secure, account, password, destinatario);
                                        setDataToFirestore();
                                    }}
                                >
                                    SALVA
                                </Button>
                            </Row>
                            
                        </Form>

                        <Modal centered
                            show={showLoading}
                            animation={false}
                            size={'sm'}
                            onHide={() => {}}
                        >
                            <div style={{padding: '20px'}}>
                                <Spinner animation="border" />
                                <label style={{margin: '0px 0px 0px 15px', verticalAlign: 'super'}}>Attendere...</label>
                            </div>
                        </Modal>
                        <Modal centered
                            show={showMessage}
                            animation={true}
                            // size={'sm'}
                            onHide={() => buttonCloseMessage && setShowMessage(false)}
                        >
                            <Modal.Header closeButton={buttonCloseMessage}>
                                <Modal.Title>{titleMessage}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <p dangerouslySetInnerHTML={{__html: bodyMessage}}></p>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" disabled={!buttonCloseMessage} onClick={() => buttonCloseMessage && setShowMessage(false)}>OK</Button>
                            </Modal.Footer>
                        </Modal>

                    </Col>
                </Card>
            </Col>
            
        </div>
    );
}

/*

interface AuthenticationTypeLogin {
    type: 'LOGIN';
    user: string;
    credentials: SMTPConnection.Credentials;
    method: string | false;
}
interface SMTPConnection.Credentials {
        user: string;
        pass: string;
}

interface AuthenticationTypeOAuth2 {
    type: 'OAUTH2';
    user: string;
    oauth2: XOAuth2;
    method: 'XOAUTH2';
}

*/