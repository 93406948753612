import React from 'react';
import './style.css';

import { Row, Col, Image } from 'react-bootstrap';

export default function Contatti() {
    return (
        <div>
            <div className="Title" style={{ position: 'relative' }}>
                {/* <img src={'http://transitteam.ch/wp-content/uploads/contact.png'} /> */}
                <img
                    className="ImageFullWidth ImageFullWidthHeight"
                    alt="image"
                    style={{
                        // height: '500px',
                        filter: 'brightness(50%)',
                    }}
                    // https://www.istockphoto.com/it/foto/donna-mano-pulsante-smartphone-connessione-commerciale-contattaci-e-call-center-gm904149760-249351723
                    src={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Ffullwidth-contacts.jpg?alt=media&token=8ea1c5ca-0703-4f60-b196-2c1af6942cc5'}
                    // src={require('./../assets/images/large/contact-us.jpg')}
                />
                <h1 className="TitlePageHoverImage">Contatti</h1>
            </div>

            <Row className="justify-content-center" style={{margin: '0px'}}>
                <Col xs={12} md={10} style={{ margin: '0px', margin: '20px 0px 35px 0px'}}>
                    {/* <h2 className="w-100 text-left" style={{margin: '0px 0px 15px 10px'}}>Contatti</h2> */}
                    <h2 className="w-100 text-left">Contattaci per info oppure fissa un appuntamento</h2>

                    <p className="InfoContatti">
                        Il supporto telefonico è attivo dal Lunedì al Venerdì dalle ore 08:30 alle ore 18:00 e il Sabato dalle ore 8:30 alle ore 12:30<br></br>
                        Segreteria telefonica sempre attiva 7 giorni su 7
                    </p>
                    
                    <Col xs={12} md={{ span: 8, offset: 1}}>
                        <Row className="align-items-start" style={{ marginTop: '50px'}}>
                            <Col md="auto">
                                <Image
                                    style={{width: '100px', height: '100px'}}
                                    alt="img_contatti"
                                    src={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fcontacts-icon.png?alt=media&token=45a0aa95-5e9e-4d4d-864a-34899b516dee'}
                                />
                            </Col>
                            <Col md={10}>
                                <h2 className="w-100 text-left">I nostri contatti</h2>

                                {/* <p class="InfoContatti">
                                    Orari di apertura:<br></br>
                                    Il supporto telefonico è attivo dal Lunedì al Venerdì dalle 08:30 alle ore 18:00 orario continuato.<br></br>
                                    Il Sabato dalle ore 8:30 alle ore 12:30<br></br>
                                    Segreteria telefonica sempre attiva 7 giorni su 7
                                </p> */}
                                
                                <p className="InfoContatti" style={{marginTop: '0px', fontWeight: 'bold'}}><li>Trezzano sul naviglio:</li></p>
                                <p className="InfoContatti" style={{marginTop: '-10px', paddingLeft: '26px'}}>Sede: <a href="https://goo.gl/maps/azrbWi9ZuTaqJgAC9" target="_blank" style={{color: 'black', textDecoration: 'underline'}}>Via F.lli Cervi 7, Trezzano S/N</a></p>
                                <p className="InfoContatti" style={{marginTop: '-15px', paddingLeft: '26px'}}>Telefono: <a href="tel:024450214" style={{color: 'black', textDecoration: 'underline'}}>024450214</a> / <a href="tel:0248402896" style={{color: 'black', textDecoration: 'underline'}}>0248402896</a></p>
                                
                                <p className="InfoContatti" style={{marginTop: '0px', fontWeight: 'bold'}}><li>Abbiategrasso:</li></p>
                                <p className="InfoContatti" style={{marginTop: '-10px', paddingLeft: '26px'}}>Sede: <a href="https://goo.gl/maps/A4N4o91cPGvS2Hq8A" target="_blank" style={{color: 'black', textDecoration: 'underline'}}>Viale Papa Giovanni XXIII, 23, Abbiategrasso</a></p>
                                <p className="InfoContatti" style={{marginTop: '-15px', paddingLeft: '26px'}}>Telefono: <a href="tel:029462464" style={{color: 'black', textDecoration: 'underline'}}>029462464</a></p>

                                <p className="InfoContatti" style={{marginTop: '0px', fontWeight: 'bold'}}>Email: <a href="mailto:amministrazione@aeffetermica.it" style={{color: 'black', textDecoration: 'underline'}}>amministrazione@aeffetermica.it</a></p>
                            </Col>
                        </Row>
                    </Col>

                </Col>
            </Row>
            
        </div>
    );
}
