import React from 'react';
import './style.css';

import InfoPage from './../components/InfoPage';

import { Image, Row, Col } from 'react-bootstrap';

export default function Contatti() {
    return (
        <div>
            <div className="Title" style={{ position: 'relative' }}>
                {/* <img src={'http://transitteam.ch/wp-content/uploads/contact.png'} /> */}
                <img
                    className="ImageFullWidth ImageFullWidthHeight"
                    alt="image"
                    style={{
                        // height: '500px',
                        filter: 'brightness(50%)',
                    }}
                    // https://www.istockphoto.com/it/foto/close-up-of-air-conditioner-gm1075473090-287934846
                    src={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Ffullwidth-condizionatori.jpg?alt=media&token=06d0bb59-311f-44db-8a8c-cc1177defd68'}
                    // src={'https://www.condizionatorimilano.com/wp-content/uploads/2019/05/ASSISTENZA.jpg'}
                />
                <h1 className="TitlePageHoverImage">Condizionatori</h1>
            </div>

            <Row className="justify-content-center" style={{ margin: '0px', marginBottom: '20px'}}>
                    <InfoPage
                        // https://www.istockphoto.com/it/foto/pulizia-del-filtro-dellaria-sporca-allinterno-dei-condizionatori-daria-con-bomboletta-gm1221795936-358283212
                        // imgLogoURL={'https://media.istockphoto.com/photos/cleaning-dirty-air-filter-inside-air-conditionerswith-water-spray-picture-id1221795936'}
                        videoUrl={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2FVID-20210414-WA0007.mp4?alt=media&token=421368d2-31c8-4874-80d4-a6eef92755fc'}
                        title={'Manutenzione'}
                        desc={'Effettuiamo manutenzione sul tuo impianto di condizionamento di qualsiasi marca con pulizia degli split interni e relativi filtri con adeguati prodotti igienizzanti, antibatterici e macchina a vapore 170°, oltre alla sanificazione della batteria esterna.'}
                    />

                    <InfoPage right
                        // https://www.istockphoto.com/it/foto/installation-service-fix-repair-maintenance-of-an-air-conditioner-indoor-unit-by-gm1169452224-323288448
                        imgLogoURL={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Finfo-condizionatori-assistenza.jpg?alt=media&token=f1b60113-69f5-450a-82be-bc3cbab7fde4'}
                        title={'Assistenza'}
                        desc={'Effettuiamo interventi di ripristino gas e ricerca di eventuali perdite sull’impianto oltre che l’analisi e la riparazione di eventuali guasti sugli split interni o motore esterno.'}
                    />

                    <InfoPage
                        // https://www.istockphoto.com/it/foto/technician-cleaning-air-conditioner-filter-gm1168365074-322572412
                        imgLogoURL={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Finfo-condizionatori-installazione.jpg?alt=media&token=e2234dc2-cb63-4712-8955-2396c4d7a152'}
                        title={'Installazione'}
                        desc={'Come installatori qualificati FGAS siamo in grado offrirvi la migliore soluzione per installazione di un impianto di climatizzazione più adatto alle vostre esigenze.'}
                    />
            </Row>
            
        </div>
    );
}
