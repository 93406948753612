export const NavigationMenu = [
    {title: 'Home'.toUpperCase(), path: '/'},
    {title: 'Caldaie'.toUpperCase(), path: '/caldaie'},
    {title: 'Condizionatori'.toUpperCase(), path: '/condizionatori'},
    // {title: 'Assistenza'.toUpperCase(), path: '/assistenza'},
    // {title: 'Faq'.toUpperCase(), path: '/faq'},
    {title: 'Contatti'.toUpperCase(), path: '/contatti'},
];

export const indexNavigation = {
    HOME: 0,
    CALDAIE: 1,
    CONDIZIONATORI: 2,
    // ASSISTENZA: 3,
    // FAQ: 3,
    CONTATTI: 3,
};

/*
    PARTITA IVA - FOOTER
    IDEO
    LOGO HEADER WIDTH TELEFONO
FUNCTIONS
    COOKIE
    INFO BANNER HOME
IMMAGINI
*/

export const firebaseConfig = {
    apiKey: "AIzaSyB9FFt2Y6TBy0DyBIMKF610DMencZLWV-A",
    authDomain: "aeffetermica-b2c11.firebaseapp.com",
    databaseURL: "https://aeffetermica-b2c11.firebaseio.com",
    projectId: "aeffetermica-b2c11",
    storageBucket: "aeffetermica-b2c11.appspot.com",
    messagingSenderId: "435240281480",
    appId: "1:435240281480:web:3047d978ca626875bd863d",
    measurementId: "G-M1BRNHZJE2"
};