import React from 'react';
import './style.css';

import InfoPage from './../components/InfoPage';

import { Image, Row, Col } from 'react-bootstrap';

export default function Contatti() {
    return (
        <div>
            <div className="Title" style={{ position: 'relative' }}>
                <img
                    className="ImageFullWidth ImageFullWidthHeight"
                    alt="image"
                    style={{
                        // height: '300px',
                        filter: 'brightness(50%)',
                    }}
                    // https://www.istockphoto.com/it/foto/worker-set-up-central-gas-heating-boiler-at-home-gm841847258-137367667
                    src={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Ffullwidth-caldaie.jpg?alt=media&token=90129dfc-b909-46c0-aace-376a42c420d9'}
                    // src={'https://assistenzacaldaie-berettaroma.it/wp-content/uploads/2018/11/Assistenza-tecnica-specializzata-Beretta.jpg'}
                />
                <h1 className="TitlePageHoverImage">Caldaie</h1>
            </div>

            <Row className="justify-content-center" style={{ margin: '0px', marginBottom: '20px'}}>
                <InfoPage
                    // https://www.istockphoto.com/it/foto/technician-repairing-combi-gas-boiler-gm1022314534-274473411
                    imgLogoURL={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Finfo-caldaia-manutenzione.jpg?alt=media&token=cdc4fb11-dd31-4a49-83d5-3681fd3a8859'}
                    title={'Manutenzioni'}
                    desc={'Stipula con noi un piano di abbonamento programmato, con prezzi agevolati, per la manutenzione e le analisi di combustione della tua caldaia.'}
                />

                <InfoPage right
                    imgLogoURL={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Finfo-caldaia-assistenza.jpg?alt=media&token=07c301f3-53d6-4300-a9a0-c7cd75d1e969'}
                    title={'Assistenza'}
                    desc={'Eseguiamo servizio di assistenza e riparazione, delle Vostre caldaie o scaldabagni sul territorio di Milano sud e provincia il nostro magazzino sempre rifornito con pezzi di ricambio originali ci permette di effettuare rapidi e risolutivi interventi.'}
                />

                <InfoPage
                    imgLogoURL={'https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Finfo-caldaia-installazione.jpg?alt=media&token=f51300d8-4957-48df-8783-3045ea15ca88'}
                    title={'Installazione nuovi generatori'}
                    desc={'Effettuiamo tramite nostro installatore esterno installazione di caldaie e scaldabagni per ogni tipologia di esigenza.'}
                />
            </Row>

            {/* <div className="Container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                
                <InfoPage
                    imgLogoURL={'https://www.assistenza-caldaie-beretta.com/wp-content/uploads/2020/08/exclusive-nuova.png'}
                    title={'Info caldaie'}
                    desc={'Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore...'}
                />

                <InfoPage right
                    imgLogoURL={'https://www.elettronew.com/30126-thickbox_default/comando-per-la-gestione-wireless-di-caldaia-beretta-besmart-wifi-20143539.jpg'}
                    title={'Revisione caldaia'}
                    desc={'Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore...'}
                />

                <InfoPage
                    imgLogoURL={'https://www.daresrl.it/wp-content/uploads/2015/11/caldaia-condensazione-viessmann-pordenone-daresrl.jpg'}
                    title={'Info caldaie 3'}
                    desc={'Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore... Cerchi la migliore caldaia per la tua casa? Rivolgiti a noi, siamo professionisti del settore...'}
                />

            </div> */}
            
        </div>
    );
}
