import React from "react";
import "./style.css";
import { Link } from 'react-router-dom';

import { NavigationMenu } from './../../constants';
import ContactModule from './../ContactModule';

import { Row, Col, ListGroup } from 'react-bootstrap';

export default function Footer() {
    return (
        <Row className="Footer">
            <Col xs={12} md={{ span: 4, offset: 0 }} className="text-center" style={{ padding: '20px'}}>
                <h2 className="TitleModule">Modulo di contatto</h2>

                <ContactModule />
            </Col>

            <Col xs={12} md={{ span: 4, offset: 1 }} className="text-center" style={{ padding: '20px'}}>
                <h2 className="TitleModule">Link utili</h2>

                <ul>
                    {
                        NavigationMenu.map((value, index) => (
                            <li key={index}><p className="Link"><a className="Link" href={value.path}>{value.title}</a></p></li>
                        ))
                    }
                </ul>

                <Col className="w-100 text-left" style={{ paddingLeft: '0px', marginTop: '30px'}}>
                    <h2 className="TitleModule">Contatti</h2>

                    {/* <p className="Info">Centro assistenza certificato Aeffetermica s.r.l.</p> */}
<p className="Info" style={{marginTop: '0px', fontWeight: 'bold', fontSize: '18px'}}><li>Trezzano sul naviglio:</li></p>
<p className="Info" style={{marginTop: '0px', paddingLeft: '26px'}}>Sede: <a href="https://goo.gl/maps/azrbWi9ZuTaqJgAC9" target="_blank" className="InfoLink">Via F.lli Cervi 7, Trezzano S/N</a></p>
<p className="Info" style={{marginTop: '0px', paddingLeft: '26px'}}>Telefono: <a href="tel:024450214" className="InfoLink">024450214</a> / <a href="tel:0248402896" className="InfoLink">0248402896</a></p>

<p className="Info" style={{marginTop: '10px', fontWeight: 'bold', fontSize: '18px'}}><li>Abbiategrasso:</li></p>
<p className="Info" style={{marginTop: '0px', paddingLeft: '26px'}}>Sede: <a href="https://goo.gl/maps/A4N4o91cPGvS2Hq8A" target="_blank" className="InfoLink">Viale Papa Giovanni XXIII, 23, Abbiategrasso</a></p>
<p className="Info" style={{marginTop: '0px', paddingLeft: '26px'}}>Telefono: <a href="tel:029462464" className="InfoLink">029462464</a></p>

{/* <p class="Info" style={{marginTop: '10px', fontWeight: 'bold', fontSize: '18px'}}><li>Abiate grasso:</li></p>
<p class="Info" style={{marginTop: '0px', paddingLeft: '26px'}}>Tel: <a href="tel:029462464" className="Link">029462464</a></p> */}

                    {/* <p className="Info">Centro assistenza certificato Aeffetermica s.r.l.</p>
                    <p className="Info InfoLink"><a href="https://goo.gl/maps/azrbWi9ZuTaqJgAC9" target="_blank">Via F.lli Cervi 7, Trezzano S/N, 20090</a></p>
                    <p className="Info">Telefono: <span className="InfoLink"><a href="tel:024450214">024450214</a></span> / <span className="InfoLink"><a href="tel:0248402896">0248402896</a></span></p> */}

                    <p className="Info" style={{ paddingLeft: '0px', marginTop: '80px', fontSize: '14px'}}
                        // style={{position: 'absolute', bottom: '0px', fontSize: '14px'}}
                    >
                        Aeffetermica s.r.l. | P. IVA 07679350962<br></br>Copyright © 2021 All rights reserved<br></br><a href='/privacy' style={{textDecoration: 'underline'}}>Privacy e Cookie policy</a>
                    </p>
                </Col>

            </Col>
        </Row>
    );
}