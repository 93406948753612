import React from "react";
import "./style.css";
import { useHistory } from 'react-router-dom';

import { Image, Row, Col } from 'react-bootstrap';

export default function BannerApp() {
    const history = useHistory();

    return (
        <Row className="BannerApp" style={{marginLeft: 0, marginRight: 0}}>
            <Col xs={12} md={6}>
                <p style={{ fontWeight: 'bold', fontSize: 26, textAlign: 'center', marginTop: '20px' }}>Scarica subito la nostra app</p>
                <p style={{ fontWeight: 'bold', fontSize: 20, marginTop: '-20px', textAlign: 'center', marginTop: '20px' }}>Disponibile per Android e iOS</p>
            </Col>
            <Col xs={12} md={6}>
                <Row className="justify-content-center" style={{marginLeft: 0, marginRight: 0, marginBottom: '20px'}}>
                    <Image
                        className="BannerAndroid"
                        style={{cursor: 'pointer'}}
                        alt="badge_android"
                        src={'./assets/images/google-play-badge.png'}
                        onClick={() => {history.push('/get-app', {data: 'android'})}}
                    />
                    <Image className="w-100"
                        className="BannerIOS"
                        style={{cursor: 'pointer'}}
                        alt="badge_ios"
                        src={'./assets/images/apple-app-badge.svg'}
                        onClick={() => {history.push('/get-app', {data: 'iphone'})}}
                    />
                </Row>
            </Col>
        </Row>
    );
}