import React, { useState, useRef, ReactDOM } from "react";
import "./style.css";

import { Form, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';

export default function ContactModule() {
    const formRef = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [bodyMessage, setBodyMessage] = useState('');

    const sendEmail = async (form) => {
        setShowLoading(true);
        const { formName, formSurname, formTel, formEmail, formMessage } = form;
        // console.log('@@@ FORM', formName.value, formSurname.value, formTel.value, formEmail.value, formMessage.value);

        // nome, cognome, telefono, email, messaggio
        const response = await fetch('https://us-central1-aeffetermica-b2c11.cloudfunctions.net/SendEmailFromModule', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({data: {
                nome: formName.value,
                cognome: formSurname.value,
                telefono: formTel.value,
                email: formEmail.value,
                messaggio: formMessage.value
            }})
        });
        const result = await response.json();
        if (result.result.status == 'OK') {
            setShowLoading(false);
            setBodyMessage('Messaggio inviato correttamente!');
            setShowMessage(true);
            formRef.current.reset();
        } else {
            setShowLoading(false);
            setBodyMessage('Errore! Non è stato possibile inviare il messaggio!');
            setShowMessage(true);
        }
    }

    return (
<div>
        <Form style={{ marginTop: '20px'}}
            ref={formRef}
            onSubmit={e => {
                e.preventDefault();
                sendEmail(e.target);
            }}
        >
            <Form.Row>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formName">
                        <Form.Label className="w-100 LabelForm">Nome</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formSurname">
                        <Form.Label className="w-100 LabelForm">Cognome</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>
                    
                </Col>
            </Form.Row>

            <Form.Group controlId="formTel">
                <Form.Label className="w-100 LabelForm">Telefono</Form.Label>
                <Form.Control type="phone" />
            </Form.Group>

            <Form.Group controlId="formEmail">
                <Form.Label className="w-100 LabelForm">Email</Form.Label>
                <Form.Control type="email" />
            </Form.Group>

            <Form.Group controlId="formMessage">
                <Form.Label className="w-100 LabelForm">Messaggio</Form.Label>
                <Form.Control as="textarea" rows={4} />
            </Form.Group>
            
            <Button variant="primary" type="submit" style={{color: 'white', fontSize: '18px', fontWeight: 'bold'}}>
                Invia modulo
            </Button>
        </Form>

        <Modal centered
            show={showLoading}
            animation={false}
            size={'sm'}
            onHide={() => {}}
        >
            <div style={{padding: '20px'}}>
                <Spinner animation="border" />
                <label style={{margin: '0px 0px 0px 15px', verticalAlign: 'super'}}>Invio in corso...</label>
            </div>
        </Modal>
        <Modal centered
            show={showMessage}
            animation={true}
            // size={'sm'}
            onHide={() => setShowMessage(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Info'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{bodyMessage}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => setShowMessage(false)}>OK</Button>
            </Modal.Footer>
        </Modal>
</div>
    );
}