import React, { useState, useEffect } from "react";
import "./style.css";

import { Navbar, Nav, Image } from 'react-bootstrap';

import { NavigationMenu } from './../../constants';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
export default function Header({ menuIndex }) {
    const [dataCaldaie, setDataCaldaie] = useState(null);
    const [dataCondizionatori, setDataCondizionatori] = useState(null);
    const [dataNavBar, setDataNavBar] = useState(null);
    const [forceReloadBg, setForceReloadBg] = useState(false);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setForceReloadBg(true);
    }, [windowDimensions]);

    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#ffffff'}} className="effect-elevation" fixed="top"
            ref={el => {
                if (!el) return;
                if ((!forceReloadBg) && (dataNavBar)) return;

                let prevValue = JSON.stringify(el.getBoundingClientRect());
                const handle = setInterval(() => {
                    let nextValue = JSON.stringify(el.getBoundingClientRect());
                    if (nextValue === prevValue) {
                        clearInterval(handle);
                        setDataNavBar(el.getBoundingClientRect());
                        setForceReloadBg(false);
                    } else {
                        prevValue = nextValue;
                    }
                }, 100);
            }}
        >
            <Navbar.Brand href="/" className="color-white header-logo" style={{}}>
                {/* <Row><h1 style={{ fontWeight: 'bold' }}>aeffe</h1><h1>termica</h1></Row> */}
                <Image
                    src="https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/app_image%2Floghi%2Flogo_orizzontale.svg?alt=media&token=13ac0ae2-b9a3-40fc-8ef3-20d9e0d9299c"
                    alt="Aeffetermica"
                    // className="header-logo"
                    style={{
                        height: '100%',
                        // width: '100%',
                    }}
                ></Image>
            </Navbar.Brand>
            <div style={{flex: 1}}></div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight: '20px'}} />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{height: '100%'}}>
                <Nav>
                    {
                        NavigationMenu.map((value, index) => {
                            let navLinkStyle = {
                                fontWeight: 'bold',
                                color: '#676767'
                            };
                            if (menuIndex == index) {
                                navLinkStyle = {...navLinkStyle,
                                    textDecoration: 'underline',
                                    textUnderlinePosition: 'under',
                                }
                            }
                            if ((value.path == '/caldaie') || (value.path == '/condizionatori')) {
                                navLinkStyle = {...navLinkStyle,
                                    color: '#ffffff',
                                }
                            }
                            if (windowDimensions.width < 1007) { // 992px
                                navLinkStyle = {...navLinkStyle,
                                    color: '#676767',
                                }
                            }
                            return (
                                <Nav.Item key={index} style={{zIndex: 10, marginTop: '5px'}}
                                    ref={el => {
                                        if (!el) return;
                                        if ((!forceReloadBg) && (dataCaldaie) && (dataCondizionatori)) return;

                                        if ((value.path == '/caldaie') || (value.path == '/condizionatori')) {
                                            let prevValue = JSON.stringify(el.getBoundingClientRect());
                                            const handle = setInterval(() => {
                                                let nextValue = JSON.stringify(el.getBoundingClientRect());
                                                if (nextValue === prevValue) {
                                                    clearInterval(handle);
                                                    if (value.path == '/caldaie') {
                                                        setDataCaldaie(el.getBoundingClientRect());
                                                    } else if (value.path == '/condizionatori') {
                                                        setDataCondizionatori(el.getBoundingClientRect());
                                                        setForceReloadBg(false);
                                                    }
                                                } else {
                                                    prevValue = nextValue;
                                                }
                                            }, 100);
                                        }

                                        // console.log('@@@@', index, el.getBoundingClientRect()); // prints 200px
                                    }}
                                >
                                    <Nav.Link href={value.path}
                                        style={navLinkStyle}
                                    >
                                        <h5 style={menuIndex == index ? {
                                                fontWeight: 'bold'
                                            } : {fontWeight: 'bold'}}>{value.title}</h5>
                                    </Nav.Link>
                                </Nav.Item>
                            );
                        })
                    }
                </Nav>
            </Navbar.Collapse>
            <div style={{
                position: 'absolute',
                top: 0,
                left: (dataCaldaie?.left ? dataCaldaie.left : 0),
                width: (dataCaldaie?.width ? dataCaldaie.width : 0),
                height: (dataNavBar?.height ? dataNavBar.height : 0),
                backgroundColor: '#e41d30',
            }}></div>
            <div style={{
                position: 'absolute',
                top: 0,
                left: (dataCondizionatori?.left ? dataCondizionatori.left : 0),
                width: (dataCondizionatori?.width ? dataCondizionatori.width : 0),
                height: (dataNavBar?.height ? dataNavBar.height : 0),
                backgroundColor: '#3a60a9',
            }}></div>
        </Navbar>
    );
}