import React, { useState, useEffect } from 'react';
import './style.css';

import { Row, Col, Image } from 'react-bootstrap';

export default function Privacy(props) {
    const [policyHTML, setPolicyHTML] = useState('');

    console.log('PROPS', props);
    const url = props.type == 'privacy' ? 'https://www.iubenda.com/api/privacy-policy/19752407/no-markup' : 'https://www.iubenda.com/api/privacy-policy/19752407/cookie-policy/no-markup'

    useEffect(() => {
        getPolicy();
    }, []);

    const getPolicy = async () => {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setPolicyHTML(data.content);
                } else {
                    setPolicyHTML('Errore! Policy non trovata. Contattare l\'amministrazione.');
                }
            });
    }

    return (
        <div>
            <Row className="justify-content-center" style={{margin: '100px 0px 0px 0px'}}>
                <Col xs={12} md={10} style={{ margin: '0px', margin: '20px 0px 35px 0px'}}>
                    
                    <Col>
                        {props.type == 'privacy' &&
                            <div style={{marginBottom: '30px'}}>
                                <p className='Info'>
                                    Vai a <a className='primary-color' href='/cookie'>Cookie Policy</a>
                                </p>
                            </div>
                        }
                        {props.type == 'cookie' &&
                            <div style={{marginBottom: '30px'}}>
                                <p className='Info'>
                                    Vai a <a className='primary-color' href='/privacy'>Privacy Policy</a>
                                </p>
                            </div>
                        }
                        <div id="privacy-policy">
                            <div
                                dangerouslySetInnerHTML={{__html: policyHTML}}
                                style={{
                                    // height: '600px',
                                    // border: '1px solid black',
                                    // overflowY: 'scroll',
                                    // borderColor: 'rgba(0, 0, 0, 0.1)',
                                    // padding: '15px',
                                }}
                            />
                        </div>
                    </Col>

                </Col>
            </Row>
        </div>
    );
}
