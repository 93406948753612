import React, { useState, useEffect } from "react";
import "./style.css";

import VisibilitySensor from 'react-visibility-sensor';
import CSSTransitionGroup from 'react-transition-group/TransitionGroup';

import { Image, Row, Col } from 'react-bootstrap';

export default function InfoPage({imgLogo, imgLogoURL, videoUrl, desc, title, right}) {
    const [classContainer, setClassContainer] = useState('container');

    return (
        <VisibilitySensor partialVisibility
            onChange={isVisible => isVisible && setClassContainer('animContainer')}
        >
            <Row className={classContainer} style={{ margin: '0px', marginTop: '20px', marginBottom: '20px', width: '80%'}}>
                <Col xs={12} md={{ span: 8, order: 2}} style={{alignSelf: 'center'}}>
                    <p className="TitlePage">{title}</p>
                    <p className="TextPage">{desc}</p>
                </Col>
                <Col xs={12} md={{ span: 4, order: (right ? 1 : 3)}}>
                    {videoUrl ? (
                        <video width="100%" height="100%" controls>
                            <source src={videoUrl} type="video/mp4"></source>
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <Image
                            className="imagePage"
                            style={{ height: '100%', width: '100%', objectFit: 'cover'}}
                            alt="image"
                            src={imgLogoURL}
                        />
                    )}
                </Col>
            </Row>
        </VisibilitySensor>
    );
}