import React from 'react';
import './style.css';

import BannerApp from './../components/BannerApp';
import BrandItem from './../components/Brand';

import { Image, Row, Col } from 'react-bootstrap';

const BRANDS = [
    {
        img: require("./../assets/loghi/viessmann.png"),
        desc: "Il Gruppo Viessmann è leader nel mondo nella produzione di caldaie e di sistemi di riscaldamento e climatizzazione per la casa, l'azienda e l'industria. La gamma completa Viessmann offre soluzioni efficienti con potenzialità da 1 a 120.000 kW sfruttando tutte le fonti di energia: gas, gasolio, legna, sole, calore naturale (aria, acqua e terra con le pompe di calore).",
    },
    {
        img: require("./../assets/loghi/vaillant.png"),
        desc: "Il gruppo Vaillant con oltre 140 anni di esperienza, continua ad offrire oggi più che mai prodotti efficienti e con una particolare attenzione rivolta all’ambiente. L'offerta si articola in una vasta gamma di prodotti come: caldaia a condensazione, pompa di calore, climatizzatore, scaldabagno, termostato, centralina climatica, pannelli solari e tanti altri prodotti che utilizzano energie rinnovabili.",
    },
    {
        img: require("./../assets/loghi/fujitsu.png"),
        desc: "Il gruppo Fujitsu leader nel settore dei climatizzatori,è la scelta ideale per rinfrescare o riscaldare la tua casa, il tuo ufficio o negozio. Fatti guidare dalla qualità!",
    },
    {
        img: require("./../assets/loghi/klover.png"),
        desc: "Nella produzione di generatori alimentati con le biomasse, KLOVER si pone nella posizione di azienda leader a livello nazionale.La parola d'ordine è \"qualità\"; le caldaie, i caminetti, le stufe e le termocucine KLOVER rispettano standard progettuali e produttivi ineguagliabili. La tradizione, la tecnologia e lo stile italiano completano l'appeal che il marchio KLOVER ha conquistato ovunque.",
    },
    {
        img: require("./../assets/loghi/beretta.png"),
        desc: "La grande competenza acquisita nel corso degli anni nel settore del riscaldamento residenziale ha reso Beretta uno dei marchi di riferimento del mercato italiano. Oggi come in origine, la ricerca dell’eccellenza in ogni attività e, più in particolare, la costante attenzione al prodotto ed ai servizi offerti, rappresentano il valore cardine di Beretta.",
    },
    {
        img: require("./../assets/loghi/sylber.png"),
        desc: "Sylber da sempre è dedita allo sviluppo di prodotti destinati a fornire il massimo comfort nell’utilizzo dell’acqua calda offrendo diversi modelli di scaldabagni a gas che si contraddistinguono per le elevate prestazioni, per la facilità di utilizzo, per la loro compattezza e per l’elegante e moderno design, inoltre vanta un catalogo completo anche di Sistemi Solari e Moduli a condensazione affermandosi così come marchio leader nel settore Idrotermosanitario.",
    },
]

export default function Index() {
    return (
        <React.Fragment>
            <Col style={{padding: 0}}>
                <Row>
                    <Col style={{paddingRight: 0}}>
                    {/* https://www.istockphoto.com/it/foto/tecnico-di-manutenzione-caldaia-di-riscaldamento-gm505603439-44706614 */}
                        <Image className="ImageFullWidth" src="https://firebasestorage.googleapis.com/v0/b/aeffetermica-b2c11.appspot.com/o/site%2Fassets%2Fimages%2Ffullwidth-home.jpg?alt=media&token=cc8b95a2-c6d2-4a7d-a597-f07ca555394e" />
                        {/* <Image className="ImageFullWidth" src="https://www.caldaie.it/wp-content/uploads/2018/11/manutenzione-caldaia.jpg" /> */}

                        <div className="BannerInfo">
                            <h2 style={{ fontSize: '35px', fontWeight: 'bold', textAlign: 'left', margin: '0px' }}>Professionisti nel settore</h2>

                            <p className="InfoContatti" style={{margin: '10px 0px 0px 0px'}}>
                                Aeffetermica srl con i suoi 40 anni di  esperienza nel settore offre assistenza,
                                manutenzione e installazione di impianti termici.
                                La professionalità, serietà e competenza del nostro personale tecnico,
                                sempre preparato grazie ai numerosi corsi di aggiornamento e specializzazione
                                potrà prontamente soddisfare ogni problematica del Vostro impianto di riscaldamento o raffrescamento.
                            </p>
                        </div>

                        <BannerApp />
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    {BRANDS.map((brand, index) => (
                        <BrandItem key={index} imgLogo={brand.img} desc={brand.desc} />
                    ))}

                    {/* <Col xs={12} md={4} className="text-center" style={{padding: 0}}>
                        <Image
                            style={{width: '90%'}}
                            alt="img_beretta"
                            src={'http://logos-vector.com/images/logo/xxl/1/3/8/138255/Sylber_ac650_450x450.png'}
                        />
                        <p className="w-100" style={{paddingLeft: '10%', paddingRight: '10%'}}>{'Azienda leader nel settore del riscaldamento, si distingue per l’eccellenza dei propri prodotti e della qualità dei servizi offerti tramite tecnici specializzati e autorizzati.'}</p>
                    </Col> */}
                </Row>
            </Col>
        </React.Fragment>
    );
}