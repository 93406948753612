import React from 'react';
import './style.css';

import firebase from "firebase/app";
import "firebase/auth";

import { Card, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';

export default function LoginPage() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            marginTop: '80px',
        }}>
            <Col xs={12} sm={12} md={6} lg={4}>
                <Card body>
                    <Col>
                        <h1 style={{textAlign: 'center', marginBottom: '40px'}}>Accesso Admin</h1>

                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            console.log('@@@ FORM', e.target.txtEmail.value, e.target.txtPassword.value);
                            firebase.auth().signInWithEmailAndPassword(e.target.txtEmail.value, e.target.txtPassword.value)
                            .then(userCredential => {
                                console.log('@@@ USER CREDENTIAL', userCredential);
                                window.location.replace('/admin/settings')
                            }).catch(error => console.log('[ERROR]', error));
                        }}>
                            <Form.Group controlId="txtEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" />
                            </Form.Group>

                            <Form.Group controlId="txtPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group>

                            <Button variant="primary" type="submit" block>
                                LOGIN
                            </Button>
                        </Form>

                    </Col>
                </Card>
            </Col>
            
        </div>
    );
}
