import React, { useState, useEffect } from "react";
import "./style.css";

import { Image, Row, Col } from 'react-bootstrap';

export default function BrandItem({imgLogo, imgLogoURL, desc}) {
    let img;
    if (imgLogo) {
        img = <img src={imgLogo} className="BrandLogo" alt="brandlogo" />;
    } else if (imgLogoURL) {
        img = <img src={imgLogoURL} className="BrandLogo" alt="brandlogo" />;
    } else {
        img = <h2>Errore</h2>;
    }

    return (
        <Col xs={12} md={4} className="text-center" style={{padding: 0, marginTop: 20}}>
            <Image
                style={{width: '90%'}}
                alt="img_logo"
                src={imgLogo}
            />
            <p className="w-100" style={{paddingLeft: '10%', paddingRight: '10%'}}>{desc}</p>
        </Col>
    );
}