import React from 'react';
import './style.css';

export default function _404PageNotFound() {
    return (
        <div className="Container" style={{marginTop: '100px', minHeight: '500px', padding: '30px'}}>
            <h1>404 - Pagina non trovata!</h1>
        </div>
    );
}
