import React, { useEffect, useState } from 'react';
import './App.css';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import { NavigationMenu, indexNavigation } from './constants';

import PageNotFound from './pages/404';
import PageIndex from './pages/index';
import PageCaldaie from './pages/caldaie';
import PageCondizionatori from './pages/condizionatori';
import PageAssistenza from './pages/assistenza';
import PageFAQ from './pages/FAQ';
import PageContatti from './pages/contatti';
import PagePrivacy from './pages/privacy';

import LoginPage from './pages/login';
import SettingsPage from './pages/settings';

import { firebaseConfig } from './constants';
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { FirestoreProvider } from "@react-firebase/firestore";

// PER COOKIE
// https://nibirumail.com/cookies/policy/?url=https%3A%2F%2Faeffetermica-b2c11.firebaseapp.com%2F&analyze=1

function App() {
    const location = useLocation();

    const getAppByDevice = () => {
        // if (window.navigator.appVersion.indexOf('iPhone') !== -1) {
        //     window.location.href = 'https://apps.apple.com/us/app/aeffetermica/id1557905195';
        //     return null;
        // } else if (window.navigator.appVersion.indexOf('Android') !== -1) {
        //     window.location.href = 'https://play.google.com/store/apps/details?id=com.ascat.aeffetermica';
        //     return null;
        // } else {
            if (location.state.data == 'iphone') {
                window.location.replace('https://apps.apple.com/us/app/aeffetermica/id1557905195');
            } else {
                window.location.replace('https://play.google.com/store/apps/details?id=com.ascat.aeffetermica');
            }
        //     return null;
        // }
    }

    const [user, setUser] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdTokenResult(true);
                setUser({...user});
                setIsAdmin(token.claims.admin || false);
            } else {
                setUser(null);
                setIsAdmin(false);
            }
        }, (error) => {
            console.log('@@@ [ERROR] USER AUTH', error);
        });
        return () => unsub();
    }, []);

    return (
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
            <div className="App">
                <Switch>
                    <Route path={NavigationMenu[indexNavigation.HOME].path} exact={true}>
                        <Header menuIndex={indexNavigation.HOME} />
                        <PageIndex />
                        <Footer />
                    </Route>

                    <Route path={NavigationMenu[indexNavigation.CALDAIE].path} exact={true}>
                        <Header menuIndex={indexNavigation.CALDAIE} />
                        <PageCaldaie />
                        <Footer />
                    </Route>

                    <Route path={NavigationMenu[indexNavigation.CONDIZIONATORI].path} exact={true}>
                        <Header menuIndex={indexNavigation.CONDIZIONATORI} />
                        <PageCondizionatori />
                        <Footer />
                    </Route>

                    {/* <Route path={NavigationMenu[indexNavigation.ASSISTENZA].path} exact={true}>
                        <Header menuIndex={indexNavigation.ASSISTENZA} />
                        <PageAssistenza />
                        <Footer />
                    </Route> */}

                    {/* <Route path={NavigationMenu[indexNavigation.FAQ].path} exact={true}>
                        <Header menuIndex={indexNavigation.FAQ} />
                        <PageFAQ />
                        <Footer />
                    </Route> */}

                    <Route path={NavigationMenu[indexNavigation.CONTATTI].path} exact={true}>
                        <Header menuIndex={indexNavigation.CONTATTI} />
                        <PageContatti />
                        <Footer />
                    </Route>

                    <Route path={'/get-app'} exact={true}
                        component={getAppByDevice}
                    />

                    <Route path={'/privacy'} exact={true}>
                        <Header menuIndex={-1} />
                        <PagePrivacy type={'privacy'} />
                        <Footer />
                    </Route>
                    <Route path={'/cookie'} exact={true}>
                        <Header menuIndex={-1} />
                        <PagePrivacy type={'cookie'} />
                        <Footer />
                    </Route>
                    
                    <Route path={'/admin'} exact={true}>
                        <Header menuIndex={-1} />
                        {user ? (
                            <Redirect to={'/admin/settings'} />
                        ) : (
                            <LoginPage />
                        )}
                    </Route>
                    {isAdmin &&
                        <Route path={'/admin/settings'} exact={true}>
                            <Header menuIndex={-1} />
                            <SettingsPage />
                        </Route>
                    }

                    <Route path="*">
                        <Header menuIndex={-1} />
                        <PageNotFound />
                        <Footer />
                    </Route>
                </Switch>
            </div>
        </FirebaseAuthProvider>
    );
}

export default App;
